var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    'background-image': `url(${_vm.config.bgImage})`,
    'background-size': 'cover',
  }),attrs:{"id":"root"}},[_c('header',[_c('Publicity',{directives:[{name:"show",rawName:"v-show",value:(!_vm.running),expression:"!running"}]}),_c('el-button',{staticClass:"res",attrs:{"type":"text"},on:{"click":function($event){_vm.showResult = true}}},[_vm._v("抽奖结果")]),_c('el-button',{staticClass:"con",attrs:{"type":"text"},on:{"click":function($event){_vm.showConfig = true}}},[_vm._v("抽奖配置")])],1),_c('div',{class:{ mask: _vm.showRes },attrs:{"id":"main"}}),_c('div',{attrs:{"id":"tags"}},_vm._l((_vm.datas),function(item){return _c('ul',{key:item.key},[_c('li',[_c('a',{style:({
            color:
              !_vm.running && _vm.allresult.includes(item.key) ? '#ff2200' : '#fff',
          }),attrs:{"href":"javascript:void(0);"}},[_vm._v(" "+_vm._s(item.name ? item.name : item.key)+" "),(item.photo)?_c('img',{attrs:{"src":item.photo,"width":50,"height":50}}):_vm._e()])])])}),0),_c('transition',{attrs:{"name":"bounce","duration":"200"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRes),expression:"showRes"}],attrs:{"id":"resbox"}},[_c('p',{on:{"click":function($event){_vm.showRes = false}}},[_vm._v(_vm._s(_vm.categoryName)+"抽奖结果：")]),_c('div',{staticClass:"container"},_vm._l((_vm.resArr),function(item){return _c('span',{key:item,staticClass:"itemres",style:(_vm.resCardStyle),attrs:{"data-id":item},on:{"click":function($event){_vm.showRes = false}}},[(!_vm.photos.find((d) => d.id === item))?_c('span',{staticClass:"cont",style:({
              fontSize: _vm.list[item - 1] && _vm.list[item - 1].name ? '32px' : null,
              lineHeight:
                _vm.list[item - 1] && _vm.list[item - 1].name ? '80px' : null,
            })},[(_vm.list[item - 1] && _vm.list[item - 1].name)?_c('span',[_vm._v(_vm._s(_vm.list[item - 1].name))]):_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(_vm.photos.find((d) => d.id === item))?_c('img',{attrs:{"src":_vm.photos.find((d) => d.id === item).value,"alt":"photo","width":160,"height":160}}):_vm._e()])}),0)])]),_c('LotteryConfig',{attrs:{"visible":_vm.showConfig},on:{"update:visible":function($event){_vm.showConfig=$event},"resetconfig":_vm.reloadTagCanvas}}),_c('Tool',{attrs:{"running":_vm.running,"closeRes":_vm.closeRes},on:{"toggle":_vm.toggle,"resetConfig":_vm.reloadTagCanvas,"getPhoto":_vm.getPhoto}}),_c('Result',{attrs:{"visible":_vm.showResult},on:{"update:visible":function($event){_vm.showResult=$event}}}),_c('span',{staticClass:"copy-right"},[_c('img',{staticClass:"logo",attrs:{"src":require("./assets/saplogo.svg")}}),_vm._v(" "+_vm._s(_vm.copyright)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }