
export const defaultState = {
  config: {
    name: 'SAP CIM Lucky Draw',
    number: 70,
    firstPrize: 2,
    secondPrize: 4,
    thirdPrize: 8,
    headerInterval: 5000,
    copyright: 'powered by Theo Sun',
    bgImage: 'https://res.cloudinary.com/drxgh9gqs/image/upload/v1673426398/pablo-heimplatz-ZODcBkEohk8-unsplash_aau4s7.jpg'
  },
  result: {
    firstPrize: [],
    secondPrize: [],
    thirdPrize: [],
  },
  newLottery: [
    { key: "firstPrize", name: `大有前"兔"奖` },
    { key: "secondPrize", name: `"兔"来运转奖` },
    { key: "thirdPrize", name: `前"兔"似锦奖` },
  ],
  list: [],
  photos: []
}
