<template>
  <div class="c-Publicity">
    <el-carousel
      height="50px"
      :autoplay="true"
      indicator-position="none"
      arrow="never"
      :interval="config.headerInterval"
    >
      <el-carousel-item v-for="item in message" :key="item.key">
        <div class="item" :class="{ actiname: item.key === 0 }">
          <span v-if="item.title" class="title">{{ item.title }}</span>
          <span v-if="item.value" class="value">{{ item.value }}</span>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { conversionCategoryName } from '@/helper/index';

export default {
  name: 'Publicity',
  computed: {
    config() {
      return this.$store.state.config;
    },
    result() {
      return this.$store.state.result;
    },
    lottery() {
      return this.$store.state.newLottery;
    },
    list() {
      return this.$store.state.list;
    },
    message() {
      const { result, config, list } = this;
      const fields = Object.keys(config);
      const message = [{ key: 0, title: config.name }];
      this.lottery.forEach(({ key, name }, index) => {
        let label = name;
        let item = key;

        if (result[item] && config[item] > 0) {
          message.push({
            key: index + 1,
            title: `${label}抽奖结果:`,
            value: `${
              result[item].length > 0
                ? result[item]
                    .map((idx) => {
                      const mapping = list.find((i) => i.key == idx);
                      if (mapping) {
                        return mapping.uid || idx;
                      } else {
                        return idx;
                      }
                    })
                    .join(', ')
                : '暂未抽取'
            }`,
          });
        }
      });

      return message;
    },
  },
};
</script>
<style lang="scss">
.c-Publicity {
  height: 100%;
  // width: 1000px;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  .item {
    text-align: center;
    color: #fff;
    font-size: 16px;
    .title {
      color: #ccc;
    }
    .value {
      margin-left: 10px;
    }
    &.actiname {
      .title {
        color: white;
        font-size: 20px;
      }
    }
  }
}
</style>
