/**
 * native crypto random value
 * 
 * @returns 
 */
export function random() {
  // return a crypto generated number
  // between 0 and 1 (0 inclusive, 1 exclusive);
  // Mimics the Math.random function in range of results
  const array = new Uint32Array(1);
  const max = Math.pow(2, 32); // normally the max is 2^32 -1 but we remove the -1
  return crypto.getRandomValues(array)[0] / max;
}


/**
 * 取范围内随机整数
 * 
 * @param {number} minNum
 * @param {number} maxNum
 */
export function randomNum(minNum = 1, maxNum) {
  return parseInt(random() * (maxNum - minNum + 1) + minNum, 10);
}
/**
 * 单次抽奖
 * @param {number} total 总人数
 * @param {array} won 已中奖
 * @param {number} num 本次抽取人数
 */
export function luckydrawHandler(total, won = [], num) {
  const peolist = [];
  for (let i = 1; i <= total; i++) {
    peolist.push(i);
  }

  const wons = won;

  const res = [];
  for (let j = 0; j < num; j++) {
    const nodraws = peolist.filter(item => !wons.includes(item));
    const current = nodraws[randomNum(1, nodraws.length) - 1];
    res.push(current);
    wons.push(current);
  }
  return res;
}
